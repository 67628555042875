<template>
  <div class="main">
    <div class="banner">
      <div class="banner-content">
        <img class="logo-image" src="/Images/logo.png" />
        <div style="display: flex; justify-content: space-between">
          <VueWriter
            class="hello-label"
            :array="arr"
            :eraseSpeed="0"
            :typeSpeed="100"
            :delay="2500"
            :intervals="0"
          />
        </div>
        <label class="heading-label"
          >KEMBANGKAN BISNIS MU KE
          <label class="heading-label-yellow">LEVEL</label> YANG LEBIH
          <label class="heading-label-yellow">TINGGI!</label></label
        >
        <label class="sub-heading-label"
          >Partner bisnis terbaikmu, dengan segudang produk terbaik yang siap
          untuk perkembangan bisnis mu.</label
        >
        <a href="mailto:halo@ptsms.co" class="responsive">Hubungi Kami</a>
        <img class="background-image" src="/Images/HeadingImage.png" />
      </div>
    </div>
    <div class="product">
      <img class="pimple" src="/Images/Pimple.png" />
      <label class="info-tag tag-product">Produk Kami</label>
      <label class="section-heading">Temukan Produk unggulan kami</label>
      <label class="section-description"
        >Perusahaan kami yang bergerak dibidang penyedia jasa, bertujuan untuk
        membantu kamu dan perusahaan kamu untuk bisa bergerak maju ke level yang
        lebih tinggi, dengan bantuan produk unggulan kami seperti
        berikut:</label
      >
      <div class="product-grid">
        <div class="product-card">
          <img class="product-image" src="/Images/Produk1.png" />
          <label class="product-name">Tech Developer</label>
          <label
            >Membantu kamu dalam membangun bisnis berupa website maupun mobile
            app.</label
          >
        </div>
        <div class="product-card">
          <img class="product-image" src="/Images/Produk2.png" />
          <label class="product-name">Property</label>
          <label>Membantu kamu dalam menjual maupun membeli property.</label>
        </div>
        <div class="product-card">
          <img class="product-image" src="/Images/Produk3.png" />
          <label class="product-name">Car Rental</label>
          <label
            >Membantu kamu dengan menyediakan mobil yang dapat kamu gunakan
            kapan saja.</label
          >
        </div>
      </div>
    </div>
    <div class="testimony">
      <label class="info-tag tag-testimony">Testimoni</label>
      <div class="testimony-content">
        <div class="t-content-b">
          <img class="t-image-1" src="/Images/mpssoft-logo-web.png" />
          <div class="testimony-content">
            <label class="t-name-1">PT. Mitra Pasifik Solusindo</label>
            <label class="t-product-1">Tech Developer</label>
          </div>
        </div>
        <div class="t-content-b">
          <img src="/Images/Petik.svg" class="t-petik-1" />
          <div class="testimony-content">
            <label class="t-testimony-1"
              >Desain & pembuatan website PT. Sinar Matahari Semesta sangat
              membantu perusahaan saya dalam menyalurkan produk yang saya buat,
              dengan adanya website ini penjualan produk saya meningkat pesat
              dalam kurun waktu yang singkat! Sukses terus PT. Sinar Matahari
              Semesta</label
            >
            <label @click="openMPS" class="t-button">Lihat Situs</label>
          </div>
          <img src="/Images/Petik.svg" class="t-petik-2" />
        </div>
      </div>
    </div>
    <div class="about">
      <div class="about-content">
        <img class="about-image" src="/Images/AboutUs.png" />
        <div class="about-section">
          <label class="info-tag tag-about">Tentang Kami</label>
          <label class="section-heading">PT. Sinar Matahari Semesta</label>
          <label
            >Kami merupakan perusahaan yang bergerak sebagai penyedia jasa,
            untuk membantu perusahaan - perusahaan yang ingin meningkatkan
            kualitas perusahaannya. Kami menyediakan jasa seperti Tech Developer
            untuk membantu meningkatkan IT dari perusahaan anda, Property untuk
            membantu mencari lokasi strategis untuk perusahaan anda, dan juga
            car rental untuk membantu transportasi bagi karyawan perusahaan
            anda.</label
          >
          <label class="product-name about-name">Mengapa harus kami?</label>
          <label
            >Kami sangat memperhatikan kualitas dari jasa yang kami sediakan
            dengan sebaik - baiknya. Client dapat memperoleh jasa dengan
            kualitas yang bagus tanpa harus mengeluarkan banyak biaya. Kebutuhan
            anda merupakan prioritaskan kami.</label
          >
        </div>
      </div>
    </div>
    <div class="contact">
      <label class="section-heading contact-heading"
        >Ingin konsultasi terlebih dahulu?</label
      >
      <label class="contact-desc"
        >Tim admission kami siap msembantu memilihkan program yang tepat untukmu
        24/7</label
      >
      <a href="mailto:halo@ptsms.co">Hubungi Kami</a>
    </div>
  </div>
</template>

<script>
import VueWriter from "vue-writer";
export default {
  name: "Main",
  components: {
    VueWriter,
  },
  data() {
    return {
      arr: ["Hello!", "Hi!", "Selamat Datang!", "Horas!", "Ni hao!", "¡Hola!"],
    };
  },
  methods: {
    openMPS() {
      window.open("https://mpssoft.co.id", "_blank");
    },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 669px;
  min-height: 669px;
  background-color: #f1eee9;
}

.banner-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 669px;
  min-height: 669px;
  position: relative;
  overflow: hidden;
  padding: 0 20px;
  max-width: 1440px;
}

.logo-image {
  width: 65px;
  z-index: 2;
  margin-top: 28px;
  margin-left: 20px;
}

.hello-label {
  color: #f18b2c;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 40px;
  margin-top: 80px;
  width: auto;
  z-index: 2;
  background-color: #fffcf9;
  margin-left: 20px;
}

.heading-label {
  color: black;
  font-weight: 700;
  font-size: 40px;
  margin: 40px 0 0;
  width: 50vw;
  z-index: 2;
  margin-left: 20px;
}

.heading-label-yellow {
  color: #f18b2c;
  z-index: 2;
}

.sub-heading-label {
  width: 30vw;
  z-index: 2;
  margin-bottom: 30px;
  margin-left: 20px;
}

.background-image {
  position: absolute;
  right: 0;
  z-index: 0;
  height: 669px;
  object-fit: cover;
}

.product {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin: 0 20px;
  position: relative;
}

.pimple {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 142px;
  max-width: 142px;
  min-height: 142px;
  max-height: 142px;
  z-index: -1;
}

.info-tag {
  color: #f18b2c;
  font-weight: bold;
  padding: 10px 40px;
  border-radius: 50px;
  background-color: #fff3e9;
  margin-top: 50px;
}

.tag-product {
  width: 95px;
}

.tag-about {
  width: 105px;
}

.section-heading {
  color: #f18b2c;
  font-weight: bold;
  font-size: 30px;
  margin-top: 20px;
}

.section-description {
  margin: 20px 160px 0;
}
.responsive {
  margin-left: 20px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 40px;
}
.product-card {
  background-color: #f1eee9;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
}

.product-image {
  margin-bottom: 30px;
  object-fit: cover;
  width: 100%;
}

.product-name {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.testimony {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  margin: 0 20px;
  position: relative;
}
.tag-testimony {
  margin-bottom: 30px;
}
.testimony-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.t-image-1 {
  margin-right: 20px;
  width: 100px;
  object-fit: contain;
}
.t-content-b {
  display: flex;
  flex-direction: row;
  position: relative;
}
.t-name-1 {
  font-weight: bold;
  font-size: 18px;
}
.t-product-1 {
  font-size: 12px;
}
.t-testimony-1 {
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
  width: 90%;
}
.t-button {
  outline: none;
  border: none;
  background-color: white;
  color: #f18b2c;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  width: 100px;
  transition: 300ms;
}
.t-button:hover {
  filter: brightness(1.2);
}

.t-petik-1 {
  position: absolute;
  top: 0px;
  left: 20px;
}

.t-petik-2 {
  position: absolute;
  bottom: 0px;
  right: 20px;
  transform: rotate(180deg);
}

.about-name {
  margin-top: 20px;
}

.about {
  background-color: #f1eee9;
  margin-top: 71px;
  display: flex;
  justify-content: center;
}

.about-content {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  margin: 0 20px;
}

.about-image {
  width: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.about-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  margin-left: 10px;
}

.contact {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  align-self: center;
  align-items: center;
  margin-bottom: 80px;
  margin: 0 20px;
}

.contact-heading {
  margin-top: 84px;
}

.contact-desc {
  margin-bottom: 40px;
}

a {
  background-color: #f18b2c;
  padding: 14px 25px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  border: none;
  z-index: 2;
  max-width: 120px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  text-decoration: none !important;
  transition: 300ms;
}

a:hover {
  filter: brightness(1.07);
}

@media screen and (max-width: 1000px) {
  .logo-image {
    margin-left: 20px;
  }
  .hello-label {
    margin-left: 20px;
  }
  .responsive {
    margin-left: 20px;
  }
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-height: 100%;
    min-height: 0;
    background-color: #f1eee9;
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    min-height: 0;
    position: relative;
    overflow: hidden;

    max-width: 1440px;
  }
  .background-image {
    position: relative;
    z-index: 0;
    height: auto;
    object-fit: cover;
    width: 100%;
    margin-top: 30px;
  }

  .heading-label {
    color: black;
    font-weight: 700;
    font-size: 36px;
    margin: 40px 0 0;
    width: 100%;
    z-index: 2;
    margin-right: 0;
    width: calc(100vw - 40px);
    align-self: center;
  }

  .sub-heading-label {
    width: 100%;
    z-index: 2;
    margin-bottom: 30px;
    margin-right: 20px;
    width: calc(100vw - 40px);
    align-self: center;
  }

  .section-description {
    margin: 20px 20px 0;
  }
}

@media screen and (max-width: 800px) {
  .about-content {
    flex-direction: column;
  }

  .about-image {
    width: 100%;
    object-fit: cover;
  }

  .about-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 0;
  }
}

@media screen and (max-width: 600px) {
  .heading-label {
    font-size: 32px;
  }
  .section-heading {
    margin: 20px 20px 0;
    font-size: 24px;
  }

  .product-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .t-testimony-1 {
    width: 80%;
  }
}
</style>
