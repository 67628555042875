<template>
  <div class="background">
    <div class="content">
      <div class="row-div respon">
        <img class="logo-image" src="/Images/logo.png" />
        <div class="footer-info">
          <label class="white-label-1"
            >Gold Coast Ofiicer Tower PIK Lantai 20 No B Kota Jakarta Utara,
            Daerah Khusus Ibukota Jakarta 14470</label
          >
          <label class="white-label-2"
            >© 2022 PT. Sinar Matahari Semesta. All right reserved.</label
          >
        </div>
      </div>
      <div class="follow-div">
        <label class="white-label-3">FOLLOW US</label>
        <div class="row-div">
          <font-awesome-icon icon="fa-brands fa-facebook-f" />
          <font-awesome-icon icon="fa-brands fa-instagram" />
          <font-awesome-icon icon="fa-brands fa-twitter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.background {
  margin-top: 40px;
  background-color: #a6a39f;
  padding: 26px 20px 47px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.content {
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 20px;
}
.row-div {
  display: flex;
  flex-direction: row;
  align-items: top;
}
.logo-image {
  min-width: 65px;
  max-width: 65px;
  min-height: 65px;
  max-height: 65px;
  margin-right: 50px;
  object-fit: contain;
}
.footer-info {
  display: flex;
  flex-direction: column;
}
.white-label-1 {
  color: white;
  width: 468px;
  margin-bottom: 25px;
}
.white-label-2 {
  color: white;
  width: 468px;
}
.white-label-3 {
  color: white;
  font-weight: bold;
  text-align: right;
  margin-bottom: 20px;
  margin-right: 20px;
}
.follow-div {
  display: flex;
  flex-direction: column;
}
.fa-twitter {
  color: white;
  background-color: #999;
  border-radius: 50%;
  padding: 10px 10px;
  cursor: pointer;
  margin-right: 20px;
}
.fa-facebook-f {
  color: white;
  background-color: #999;
  border-radius: 50%;
  margin-right: 25px;
  padding: 10px 12px;
  cursor: pointer;
}
.fa-instagram {
  color: white;
  background-color: #999;
  border-radius: 50%;
  margin-right: 25px;
  padding: 10px 11px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .content {
    flex-direction: column;
  }
  .respon {
    flex-direction: column;
  }
  .white-label-2 {
    width: 100%;
    margin-bottom: 30px;
  }
  .white-label-1 {
    width: 100%;
  }
  .white-label-3 {
    width: 100%;
    text-align: left;
  }
}
</style>
