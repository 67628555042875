<template>
  <MainPage />
  <Footer />
</template>

<script>
import MainPage from "./components/Main.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "SPA",
  components: {
    MainPage,
    Footer,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");

body {
  font-family: "Poppins";
  font-size: 14px;
  color: #767676;
  margin: 0;
}

#app {
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  width: 100vw;
  overflow: hidden;
}
</style>
